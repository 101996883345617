import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Router, useRouter } from 'next/router';
import { useUserData } from '../../hooks/useUserData';
import styled from 'styled-components';
import { Navbar } from './Navigation/Navbar';
import { LoadingScreen } from '../../components/LoadingScreen';
import { Box, Grid, Hidden } from '@mui/material';
import { Sidebar } from './Navigation/Sidebar';
import Head from 'next/head';
import { Routes } from '../../enums/routes';
import { ScrollProgressBar } from '../../components/ScrollProgressBar';
import { ErrorBoundary } from '../ErrorBoundary';
import { colors } from '../../styles/colors';
import { useWhiteLabelContext } from '../WhitelabeProvider';
import nookies from 'nookies';

type Props = {
  title?: string;
  maxWidth?: number;
  protectedRoute?: boolean;
  showScrollProgress?: boolean;
  children: ReactNode;
  paddingTop?: number;
  paddingBottom?: number;
};

export const MainLayout: FC<Props> = (props) => {
  const {
    children,
    protectedRoute = true,
    maxWidth = 1050,
    title,
    showScrollProgress,
    paddingTop = 60,
    paddingBottom = 60,
  } = props;
  const router = useRouter();
  const [changingRoute, setChangingRoute] = useState(false);
  const { user, loading } = useUserData();

  if (user) {
    nookies.set({}, 'userId', user.id, {
      path: '/',
    });
  }

  useEffect(() => {
    const turnOnRouteLoading = () => setChangingRoute(true);
    const turnOffRouteLoading = () => setChangingRoute(false);

    router.events.on('routeChangeStart', turnOnRouteLoading);
    router.events.on('routeChangeComplete', turnOffRouteLoading);

    return () => {
      router.events.off('routeChangeStart', turnOnRouteLoading);
      router.events.off('routeChangeComplete', turnOffRouteLoading);
    };
  }, [router]);

  // useEffect(() => {
  //   const checkAuthentication = async () => {
  //     if (!gotToken && protectedRoute) {w
  //       await router.push(Routes.Login);
  //     }
  //   };
  //
  //   checkAuthentication();
  // }, []);

  useEffect(() => {
    const checkRoutes = async () => {
      if (protectedRoute && !user && !loading) {
        await router.push(Routes.Login);
        return;
      }

      if (user && !loading) {
        const { profile, onboardingData } = user;
        const onExcludedRoutes = [
          Routes.UserProfileCreation,
          Routes.Onboarding,
        ].includes(router.pathname as Routes);

        if (!profile && !changingRoute && !onExcludedRoutes) {
          await router.push(Routes.UserProfileCreation);
          return;
        }

        if (!onboardingData && !changingRoute && !onExcludedRoutes) {
          await router.push(Routes.Onboarding);
          return;
        }
      }
    };

    checkRoutes();
  }, [user, loading]);

  const { loading: loadingWhitelabel } = useWhiteLabelContext();

  if (loadingWhitelabel) {
    return <LoadingScreen />;
  }

  if (protectedRoute === false || user) {
    const component = changingRoute ? <LoadingScreen logo={false} /> : children;

    return (
      <PageLayout>
        <WidthCover>
          <Head>
            <title>{title}</title>
          </Head>

          {showScrollProgress && <ScrollProgressBar />}

          <Hidden lgUp>
            <Navbar />

            <ErrorBoundary>
              <MobilePaddingWrapper>
                <WidthWrapper maxWidth={maxWidth}>{component}</WidthWrapper>
              </MobilePaddingWrapper>
            </ErrorBoundary>
          </Hidden>

          <Hidden lgDown>
            <Grid container direction="row" wrap="nowrap" width={'100%'}>
              <Sidebar />

              <ErrorBoundary>
                <DesktopWrapper
                  paddingTop={paddingTop}
                  paddingBottom={paddingBottom}
                >
                  <WidthWrapper maxWidth={maxWidth}>{component}</WidthWrapper>
                </DesktopWrapper>
              </ErrorBoundary>
            </Grid>
          </Hidden>
        </WidthCover>
      </PageLayout>
    );
  }

  return <LoadingScreen />;
};

const MobilePaddingWrapper = styled.div`
  margin: 0 auto;
  padding: 16px;
  margin-top: 50px;
`;

const DesktopWrapper = styled.div<{
  paddingTop?: number;
  paddingBottom?: number;
}>`
  width: 100%;
  padding: 60px;
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;
  margin-left: 270px;
  height: 100%;
  //overflow: scroll;
`;

export const WidthWrapper = styled.div<{ maxWidth: number }>`
  width: 100%;
  max-width: ${(props) => props.maxWidth}px;
  margin: 0 auto;
`;

const PageLayout = styled.div`
  //
  width: 100%;

  height: 100%;
  min-height: 100vh;
  background-color: ${colors.brand};
`;

const WidthCover = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;

  max-width: 1420px;
  margin: 0 auto;
`;
