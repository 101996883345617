import { Routes } from '../../../enums/routes';
import { FoldersIcon } from '../../../components/Icon/Icons/Folder.icon';
import { FC } from 'react';
import { DashboardIcon } from '../../../components/Icon/Icons/Dashboard.icon';
import { getUser_getUser } from '../../../../apollo/queries/__generated__/getUser';
import { t } from '@lingui/macro';
import { DealIcon } from '../../../components/Icon/Icons/Deal.icon';
import { SaleCouponsIcon } from '../../../components/Icon/Icons/SaleCoupons.icon';
import { AvatarIcon } from '../../../components/Icon/Icons/Avatar.icon';
import { OpenNew } from '../../../components/Icon/Icons/OpenNew.icon';
import { CommunityLink, WhiteLabelContext } from '../../WhitelabeProvider';
import { AiAdviserIcon } from '../../../components/Icon/Icons/AiAdviser.icon';

type Links = {
  href: string;
  label: string;
  hide?: boolean;
  linkIcon: FC;
  external?: boolean;
  className?: string;
}[];

type NavigationStructure = {
  header: string;
  links: Links;
}[];

type Props = {
  user: getUser_getUser;
  isAdmin: boolean;
  communityLink: CommunityLink;
  linkToFeedbackForm: WhiteLabelContext['linkToFeedbackForm'];
  isLinkToPartnersVisible: boolean;
};
type GetNavigationsStructure = (props: Props) => NavigationStructure;

export const getNavigationStructure: GetNavigationsStructure = ({
  user,
  isAdmin,
  communityLink,
  linkToFeedbackForm,
  isLinkToPartnersVisible,
}) => {
  const showLinks = user?.profile && user?.onboardingData;

  if (!showLinks) {
    return [];
  }

  const baseLinks: NavigationStructure = [
    {
      header: t`menu`,
      links: [
        {
          href: Routes.Admin,
          label: 'Admin',
          linkIcon: DashboardIcon,
          hide: !isAdmin,
        },
        {
          href: Routes.Dashboard,
          label: t`Dashboard`,
          linkIcon: DashboardIcon,
        },
        {
          href: Routes.AiAdviser,
          label: t`AI Buddy`,
          linkIcon: AiAdviserIcon,
          className: 'ai-button',
        },
        {
          href: Routes.MyProjects,
          label: t`Moje projekty`,
          linkIcon: FoldersIcon,
        },
        {
          href: Routes.Specialists,
          label: t`Odborníci`,
          linkIcon: DealIcon,
          hide: !isLinkToPartnersVisible,
        },
        {
          href: Routes.SpecialOffers,
          label: t`Výhody a slevy`,
          linkIcon: SaleCouponsIcon,
        },
      ],
    },
    {
      header: t`odkazy`,
      links: [
        {
          href:
            communityLink?.url ||
            'https://www.facebook.com/groups/378113943455510',
          label: communityLink?.label || t`Komunita`,
          linkIcon: OpenNew,
          external: true,
        },
      ],
    },
    {
      header: t`můj účet`,
      links: [
        {
          href: Routes.UserProfileEdit,
          label: `${user.firstName} ${user.lastName}`,
          linkIcon: AvatarIcon,
        },
      ],
    },
  ];

  if (linkToFeedbackForm) {
    baseLinks[1].links.push({
      href: linkToFeedbackForm.url,
      label: linkToFeedbackForm.label,
      linkIcon: OpenNew,
      external: true,
    });
  }

  return baseLinks;
};
