import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const DealIcon: FC<IconSVGProps> = ({ width = 24, height = 25 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="contacts,address-book">
      <path
        id="vector"
        d="M19.25 21.8662H6C5.30964 21.8662 4.75 21.3066 4.75 20.6162M11.5 9.86621H12.5M4.75 20.6162V6.36621C4.75 4.70936 6.09315 3.36621 7.75 3.36621H19.25V19.3662H6C5.30964 19.3662 4.75 19.9259 4.75 20.6162ZM9.75 14.3662C9.75 14.3662 9.75 12.8662 12 12.8662C14.25 12.8662 14.25 14.3662 14.25 14.3662H9.75ZM13 9.86621C13 10.4185 12.5523 10.8662 12 10.8662C11.4477 10.8662 11 10.4185 11 9.86621C11 9.31393 11.4477 8.86621 12 8.86621C12.5523 8.86621 13 9.31393 13 9.86621Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
